<template>
    <y-page-head
        :title="header.title.label"
        :icon="header.title.icon"
        :desc="header.title.description"
    >
        <template slot="actions">
            <y-button
                v-if="header.refresh_button"
                id="refresh-button"
                type="button"
                icon="md-refresh"
                aria-label="Refresh"
                @click.prevent="refreshTab"
            />

            <!-- Sort -->
            <y-grid-header-filters-sort
                v-if="hasSort"
                :sort-fields="get(grid, 'header.sort_fields')"
                :value="model"
                @refresh="refresh"
            />

            <!-- Filters -->
            <y-grid-header-filters-form
                v-if="hasFilters"
                :grid="grid"
                :filters-form="filtersForm"
                :value="model"
                @set-filters-form="$emit('set-filters-form', $event)"
                @refresh="refresh"
            />

            <div
                v-if="header.buttons"
                class="buttons"
            >
                <y-form-link
                    v-for="button in header.buttons"
                    :key="`grid-header-button-${button.id}`"
                    :element="button"
                    type="button"
                />
            </div>

            <!-- Mass Actions -->
            <y-grid-header-mass-actions
                v-if="hasMassActions"
                :actions="massActions"
                :selected="grid.selected"
            />

            <!-- Search -->
            <y-grid-header-search
                v-if="hasSearch"
                :value="model"
                @refresh="refreshSearch"
            />
        </template>

        <y-grid-header-tabs
            v-if="tabs && !Array.isArray(tabs)"
            slot="tabs"
            :tabs="tabs"
            :value="model"
            @refresh="refresh"
        />
    </y-page-head>
</template>

<script>

    import { YFormLink } from '@deps';
    import YPageHead from '@deps/PageHead';

    export default {

        name: 'GridHeader',

        components: {
            YFormLink,
            YPageHead,
            YGridHeaderFiltersForm: () => import('@deps/grid/header/FiltersForm'),
            YGridHeaderFiltersSort: () => import('@deps/grid/header/FiltersSort'),
            YGridHeaderMassActions: () => import('@deps/grid/header/MassActions'),
            YGridHeaderSearch     : () => import('@deps/grid/header/Search'),
            YGridHeaderTabs       : () => import('@deps/grid/header/Tabs'),
        },

        props: {
            value      : Object,
            grid       : Object,
            filtersForm: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
            };
        },

        computed: {

            /**
             * Return header of grid
             */
            header() {
                return this.grid.header;
            },

            /**
             * Check if has sort
             */
            hasSort() {
                return !!this.get(this.header, 'sort_fields');
            },

            /**
             * Check if has filters
             */
            hasFilters() {
                return !!this.get(this.header, 'has_filters');
            },

            /**
             * Return mass actions
             *
             * @returns {*}
             */
            massActions() {
                return this.get(this.header, 'mass_actions', {});
            },

            /**
             * Return if has Mass actions
             *
             * @returns {*}
             */
            hasMassActions() {
                return this.massActions && Object.keys(this.massActions).length;
            },

            /**
             * Check if grid has search
             */
            hasSearch() {
                return !!this.get(this.header, 'search');
            },

            /**
             * Get grid tabs
             */
            tabs() {
                return this.get(this.header, 'tabs');
            },

        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', newVal);
                    }
                },
                deep     : true,
                immediate: true,
            },
        },

        methods: {

            /**
             * Set model defaults
             *
             * @param $event
             */
            setModel($event) {
                const model = {
                    ...this.model,
                    ...$event,
                    sort   : $event.sort || this.$route.query.sort || 'created_at',
                    order  : $event.order || this.$route.query.order || 'desc',
                    refresh: $event.refresh || 'grid',
                    search : null,
                };
                this.$set(this, 'model', model);
            },

            /**
             * Do the refresh sort
             *
             * @param $event
             */
            refresh($event) {
                this.setModel($event);
                this.$emit('input', this.model);
            },

            /**
             * Refresh grid by search
             *
             * @param $event
             */
            refreshSearch($event) {
                this.$set(this, 'model', {
                    refresh: 'grid',
                    search : $event.search,
                    sort   : this.$route.query.sort || 'created_at',
                    order  : this.$route.query.order || 'desc',
                });
                this.$emit('input', this.model);
            },

            /**
             * Refresh grid by tab
             */
            refreshTab() {
                this.$emit('input', {
                    ...this.model,
                    refresh: 'grid',
                    page   : this.$route.query.page || 1,
                });
            },

        },

    };
</script>
